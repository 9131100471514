/// <reference path="../reference.ts" />

module project {

	export class VerticesGenerator {

		private static _printVertices(id:string, vertices:number[]):void {
			console.log("	public static " + id + ":number[] = [" + vertices.join(", ") + "];");
		}

		public static generateBox(particleCount:number):number[] {
			const visibleParticleCount:number = 6000;
			const size:number = 1000;

			let vertices:number[] = [];
			let vertexIndex:number = 0;
			let x:number;
			let y:number;
			let z:number;

			while (vertexIndex < visibleParticleCount) {
				x = Math.floor((Math.random() - 0.5) * size);
				y = Math.floor((Math.random() - 0.5) * size);
				z = Math.floor((Math.random() - 0.5) * size);
				vertices.push(x, y, z);
				++vertexIndex;
			}

			const invisibleSize:number = 10000;
			while (vertexIndex < particleCount) {
				x = Math.floor((Math.random() - 0.5) * invisibleSize);
				y = Math.floor((Math.random() - 0.5) * invisibleSize);
				z = Math.floor((Math.random() - 0.5) * invisibleSize);
				vertices.push(x, y, z);
				++vertexIndex;
			}

			console.log("[VerticesGenerator] BOX : " + vertices.length);
			return vertices;
		}

		public static generateSphere(particleCount:number):number[] {
			const visibleParticleCount:number = 6000;
			const size1:number = 600;
			const size2:number = 1500;

			let vertices:number[] = [];
			let vertexIndex:number = 0;
			const radius1:number = size1 * 0.5;
			const radius2:number = size2 * 0.5;
			const radius1_2:number = radius1 * radius1;
			const radius2_2:number = radius2 * radius2;
			let x:number;
			let y:number;
			let z:number;
			let threshold:number;
			//let ratio:number;
			//const branchRatio:number = 0.7;

			while (vertexIndex < visibleParticleCount) {
				if (vertexIndex < visibleParticleCount)
					x = Math.floor((Math.random() - 0.5) * size2);
				y = Math.floor((Math.random() - 0.5) * size2);
				z = Math.floor((Math.random() - 0.5) * size2);

				threshold = vertexIndex < visibleParticleCount * 0.8 ? radius1_2 : radius2_2;

				/*
				 ratio = vertexIndex / visibleParticleCount;
				 if (ratio < branchRatio) {
				 threshold = radius1_2;
				 } else {
				 threshold = radius1_2 + ((ratio - branchRatio) / (1 - branchRatio)) * (radius2_2 - radius1_2);
				 }
				 */

				if (x * x + y * y + z * z <= threshold) {
					vertices.push(x, y, z);
					++vertexIndex;
				}
			}

			const invisibleSize:number = 10000;
			while (vertexIndex < particleCount) {
				x = Math.floor((Math.random() - 0.5) * invisibleSize);
				y = Math.floor((Math.random() - 0.5) * invisibleSize);
				z = Math.floor((Math.random() - 0.5) * invisibleSize);
				vertices.push(x, y, z);
				++vertexIndex;
			}

			console.log("[VerticesGenerator] SPHERE : " + vertices.length);
			return vertices;
		}

		public static generateRandom(particleCount:number):number[] {
			const size:number = 1000;

			let vertices:number[] = [];
			let vertexIndex:number = 0;
			const radius:number = size * 0.5;
			const radius2:number = radius * radius;
			let x:number;
			let y:number;
			let z:number;

			while (vertexIndex < particleCount) {
				x = Math.floor((Math.random() - 0.5) * size);
				y = Math.floor((Math.random() - 0.5) * size);
				z = Math.floor((Math.random() - 0.5) * size);
				if (x * x + y * y + z * z <= radius2) {
					vertices.push(x, y, z);
					++vertexIndex;
				}
			}

			console.log("[VerticesGenerator] RANDOM : " + vertices.length);
			return vertices;
		}

		public static generateM(particleCount:number):number[] {
			//const visibleParticleCount:number = 6000;
			//const size:number = 1000;

			let vertices:number[] = Vertices.M;
			let vertexIndex:number = vertices.length / 3;
			let x:number;
			let y:number;
			let z:number;

			const invisibleSize:number = 10000;
			while (vertexIndex < particleCount) {
				x = Math.floor((Math.random() - 0.5) * invisibleSize);
				y = Math.floor((Math.random() - 0.5) * invisibleSize);
				z = Math.floor((Math.random() - 0.5) * invisibleSize);
				vertices.push(x, y, z);
				++vertexIndex;
			}

			console.log("[VerticesGenerator] M : " + vertices.length);
			return vertices;
		}

		public static generateA(particleCount:number):number[] {
			//const visibleParticleCount:number = 6000;
			//const size:number = 1000;

			let vertices:number[] = Vertices.A;
			let vertexIndex:number = vertices.length / 3;
			let x:number;
			let y:number;
			let z:number;

			const invisibleSize:number = 10000;
			while (vertexIndex < particleCount) {
				x = Math.floor((Math.random() - 0.5) * invisibleSize);
				y = Math.floor((Math.random() - 0.5) * invisibleSize);
				z = Math.floor((Math.random() - 0.5) * invisibleSize);
				vertices.push(x, y, z);
				++vertexIndex;
			}

			console.log("[VerticesGenerator] A : " + vertices.length);
			return vertices;
		}

		public static generateI(particleCount:number):number[] {
			const visibleParticleCount:number = 6000;
			const w:number = 220;
			const h:number = 1000;
			const d:number = 200;

			let vertices:number[] = [];
			let vertexIndex:number = 0;
			let x:number;
			let y:number;
			let z:number;

			while (vertexIndex < visibleParticleCount) {
				x = Math.floor((Math.random() - 0.5) * w);
				y = Math.floor((Math.random() - 0.5) * h);
				z = Math.floor((Math.random() - 0.5) * d);
				vertices.push(x, y, z);
				++vertexIndex;
			}

			const invisibleSize:number = 10000;
			while (vertexIndex < particleCount) {
				x = Math.floor((Math.random() - 0.5) * invisibleSize);
				y = Math.floor((Math.random() - 0.5) * invisibleSize);
				z = Math.floor((Math.random() - 0.5) * invisibleSize);
				vertices.push(x, y, z);
				++vertexIndex;
			}

			console.log("[VerticesGenerator] I : " + vertices.length);
			return vertices;
		}

		public static generateProduct(particleCount:number):number[] {
			const loadingManager:any = new THREE.LoadingManager();
			loadingManager.onLoad = ():void => {
				//console.log("model load complete");
			};
			loadingManager.onProgress = (item:any, loaded:number, total:number):void => {
				//console.log("model load proress : " + item + " ("+ loaded + " / " + total + ")");
			};
			loadingManager.onError = ():void => {
				//console.log("model load error");
			};
			const loader:any = new THREE.OBJLoader(loadingManager);
			loader.load("../../asset/model/miami_mtr2_convert.obj", (model):void => {
				const scale:number = 30;
				const bufferGeometry = model.children[0].geometry;
				const bufferAttribute = bufferGeometry.attributes.position;
				const bufferArray:number[] = bufferAttribute.array;
				let vertexCount = bufferArray.length / bufferAttribute.itemSize;
				let vertexMinY = Number.MAX_VALUE;
				let vertexMaxY = -Number.MAX_VALUE;
				let vertices:number[] = [];
				console.log("vertex count before : " + vertexCount);
				const positions:Object = new Object();
				for (let i = 0; i < vertexCount; ++i) {
					let px = bufferArray[i * 3];
					let py = bufferArray[i * 3 + 1];
					let pz = bufferArray[i * 3 + 2];
					if (positions[px + "," + py + "," + pz] != null)
						continue;
					else
						positions[px + "," + py + "," + pz] = true;
					if (py < vertexMinY) { vertexMinY = py; }
					if (py > vertexMaxY) { vertexMaxY = py; }
					px = Math.floor(px * scale);
					py = Math.floor(py * scale);
					pz = Math.floor(pz * scale);
					vertices.push(px, py, pz);
				}
				vertexCount = vertices.length;
				console.log("vertex count after: %d", vertexCount);
				const offsetY:number = Math.floor(-(vertexMaxY + vertexMinY) * 0.5 * scale);
				for (let i = 0; i < vertexCount / 3; ++i) {
					vertices[i * 3 + 1] += offsetY;
				}
				const particleCount:number = Config.PARTICLE_COUNT;
				const particleVertexCount:number = particleCount * 3;
				const particleVertices:number[] = [];
				const vertexStep:number = Math.floor(vertexCount / particleVertexCount);
				let vertexIndex:number = 0;
				let vertexIndices:number[] = [];
				for (let i = 0; i < particleCount; ++i) {
					vertexIndices.push(i);
				}
				for (let i = 0; i < particleCount; ++i) {
					//const index:number = vertexIndex < vertexCount ? vertexIndex : Math.floor(Math.random() * vertexCount / 3);
					const index:number = vertexIndices.splice(Math.floor(vertexIndices.length * Math.random()), 1)[0];
					const px:number = vertices[index * 3];
					const py:number = vertices[index * 3 + 1];
					const pz:number = vertices[index * 3 + 2];
					particleVertices.push(px, py, pz);
					vertexIndex += vertexStep;
				}
				console.log("[VerticesGenerator] PRODUCT : " + vertices.length);
				this._printVertices("PRODUCT", particleVertices);
			}, (xhr:any):void => {
				if (xhr.lengthComputable) {
					const percent:number = Math.round(xhr.loaded / xhr.total * 100);
					const text:string = percent + '% downloaded';
					//console.log(text);
				}
			}, (xhr:any):void => {
			});
			return [];
		}
	}
}