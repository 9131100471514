/// <reference path="../reference.ts" />

module project {

	export class PointCloudContext {

		constructor() {
			//参照カラー
			const colorSchemes:number[][] = [
				ColorScheme.MIAMI,
				ColorScheme.MONOTONE
				/*
				ColorScheme.EARTH,
				ColorScheme.VIOLET,
				ColorScheme.ORANGE,
				//ColorScheme.GREEN,
				ColorScheme.BLUE
				*/
			];

			this.particleReferenceColorSchemeCount = colorSchemes.length;
			this.particleReferenceColorSchemes = new Array<Color[]>(this.particleReferenceColorSchemeCount);
			var colorScheme:number[];
			var colorCount:number;
			var color:any;
			var colorDuplicatedCount:number;
			for (var i:number = 0; i < this.particleReferenceColorSchemeCount; ++i) {
				colorScheme = colorSchemes[i];
				colorCount = colorScheme.length / 2;
				this.particleReferenceColorSchemes[i] = [];
				for (var j:number = 0; j < colorCount; ++j) {
					color = new THREE.Color(colorScheme[j * 2]);
					colorDuplicatedCount = colorScheme[j * 2 + 1];
					for (var k:number = 0; k < colorDuplicatedCount; ++k) {
						this.particleReferenceColorSchemes[i].push(color);
					}
				}
			}

			this.particleColorSchemeIndex = -1;
			this.setNextParticleColorScheme();

			//パーティクル
			Vertices.BOX = VerticesGenerator.generateBox(Config.PARTICLE_COUNT);
			Vertices.SPHERE = VerticesGenerator.generateSphere(Config.PARTICLE_COUNT);
			Vertices.RANDOM = VerticesGenerator.generateRandom(Config.PARTICLE_COUNT);
			Vertices.M = VerticesGenerator.generateM(Config.PARTICLE_COUNT);
			Vertices.I = VerticesGenerator.generateI(Config.PARTICLE_COUNT);
			Vertices.A = VerticesGenerator.generateA(Config.PARTICLE_COUNT);

			this.particleCount = Config.PARTICLE_COUNT;
			this.particleContexts = new Array<ParticleContext>(this.particleCount);
			this.particleColors = new Array<number>(this.particleCount);
			this.particleVisibilities = new Array<number>(this.particleCount);
			for (var i:number = 0; i < this.particleCount; ++i) {
				this.particleContexts[i] = new ParticleContext(i);
				this.particleVisibilities[i] = 1;
				this.particleColors[i] = this.particleReferenceColors[Math.floor(Math.random() * this.particleReferenceColorCount)];
			}

			//初期状態
			this.reset();

			this.isParticleVisibilitiesUpdated = false;
			this.isParticleColorsUpdated = false;

			this.floatingRotation = 0;
			this.floatingRotationTarget = 0;

			this.scrollRotation = 0;
			this.scrollRotationTarget = 0;
			this.scrollRotationAccel = 0;
			this.scrollRotationVelocity = 0;
		}

		public reset():void {
			this.particleColorTransformDuration = 15;

			this.useShuffleParticlePosition = true;
			this.useShuffleParticleColor = true;
			this.particleShuffleDistance = 200;

			this.floatingRotationVelocity = 0.001;
			this.scrollRotationEasing = 1;
		}

		public update(frameCount:number, useTransition:Boolean = true):void {
			var positionEasing:number;
			var angularVelocityEasing:number;
			var angleEasing:number;
			if (useTransition) {
				positionEasing = 0.1 / (1 + Math.abs(this.scrollRotationVelocity) * 5);
				angleEasing = 0.1;
				angularVelocityEasing = 0.1;
			} else {
				positionEasing = 1;
				angleEasing = 1;
				angularVelocityEasing = 1;
			}

			var particleContext:ParticleContext;
			var particlePosition:Vector3;
			var particleColorTimer:number;
			for (var i:number = 0; i < this.particleCount; ++i) {
				particleContext = this.particleContexts[i];
				particlePosition = particleContext.position;

				particlePosition.x += (particleContext.targetX - particlePosition.x) * positionEasing;
				particlePosition.y += (particleContext.targetY - particlePosition.y) * positionEasing;
				particlePosition.z += (particleContext.targetZ - particlePosition.z) * positionEasing;

				/*
				particlePosition.x += particleContext.velocityX;
				particlePosition.y += particleContext.velocityY;
				particlePosition.z += particleContext.velocityZ;

				particleContext.velocityX *= 0.95;
				particleContext.velocityY *= 0.95;
				particleContext.velocityZ *= 0.95;
				*/

				particleColorTimer = particleContext.colorTimer;
				if (particleColorTimer > 0) {
					--particleColorTimer;
					if (particleColorTimer <= 0) {
						particleColorTimer = 0;
						this.particleColors[i] = this.particleReferenceColors[particleContext.colorIndex];
						this.isParticleColorsUpdated = true;
					}
					particleContext.colorTimer = particleColorTimer;
				}
			}

			this.floatingRotationTarget += this.floatingRotationVelocity;
			this.floatingRotation += (this.floatingRotationTarget - this.floatingRotation) * 0.1;

			this.scrollRotationVelocity += this.scrollRotationAccel;
			this.scrollRotationTarget += this.scrollRotationVelocity;
			this.scrollRotation += (this.scrollRotationTarget - this.scrollRotation) * this.scrollRotationEasing;
			this.scrollRotationAccel *= 0.9;
			this.scrollRotationVelocity *= 0.9;
		}

		public tick(tickCount:number, useTransition:Boolean = true):void {
			var particleCount = this.particleCount;
			var particleContext:ParticleContext;
			var particlePosition:Vector3;
			var particleShuffleDistance:number = this.particleShuffleDistance + Math.abs(this.scrollRotationVelocity) * 2000;

			var isColorSchemeUpdated:boolean = false;
			if (tickCount % 8 == 0) {
				isColorSchemeUpdated = true;
				this.setNextParticleColorScheme();
			}

			if (this.useShuffleParticlePosition && this.useShuffleParticleColor) {
				this.isParticleColorsUpdated = true;
				for (var i = 0; i < particleCount; ++i) {
					particleContext = this.particleContexts[i];

					particlePosition = particleContext.position;
					particlePosition.x += (Math.random() - 0.5) * particleShuffleDistance;
					particlePosition.y += (Math.random() - 0.5) * particleShuffleDistance;
					particlePosition.z += (Math.random() - 0.5) * particleShuffleDistance;

					if (isColorSchemeUpdated) {
						particleContext.colorTimer = 1 + Math.floor(Math.random() * this.particleColorTransformDuration);
						particleContext.colorIndex = Math.floor(Math.random() * this.particleReferenceColorCount);
					} else {
						this.particleColors[i] = this.particleReferenceColors[Math.floor(Math.random() * this.particleReferenceColorCount)];
					}
				}

			} else if (this.useShuffleParticlePosition) {
				for (var i = 0; i < particleCount; ++i) {
					particlePosition = this.particleContexts[i].position;
					particlePosition.x += (Math.random() - 0.5) * particleShuffleDistance;
					particlePosition.y += (Math.random() - 0.5) * particleShuffleDistance;
					particlePosition.z += (Math.random() - 0.5) * particleShuffleDistance;
				}

			} else if (this.useShuffleParticleColor) {
				this.isParticleColorsUpdated = true;
				for (var i = 0; i < particleCount; ++i) {
					particleContext = this.particleContexts[i];

					if (isColorSchemeUpdated) {
						particleContext.colorTimer = 1 + Math.floor(Math.random() * this.particleColorTransformDuration);
						particleContext.colorIndex = Math.floor(Math.random() * this.particleReferenceColorCount);
					} else {
						this.particleColors[i] = this.particleReferenceColors[Math.floor(Math.random() * this.particleReferenceColorCount)];
					}
				}
			}
		}

		public setNextParticleColorScheme() {
			var colorSchemeIndex:number = this.particleColorSchemeIndex + 1;
			if (colorSchemeIndex >= this.particleReferenceColorSchemeCount) colorSchemeIndex = 0;

			this.particleColorSchemeIndex = colorSchemeIndex;
			this.particleReferenceColors = this.particleReferenceColorSchemes[this.particleColorSchemeIndex];
			this.particleReferenceColorCount = this.particleReferenceColors.length;
		}

		public setScrollVelocity(scrollVelocity:number):void {
			var scrollPower:number = -scrollVelocity / 1000;

			var maxScrollPower = 0.05;
			if (scrollPower > maxScrollPower) scrollPower = maxScrollPower;
			else if (scrollPower < -maxScrollPower) scrollPower = -maxScrollPower;

			this.scrollRotationAccel = scrollPower;
		}





		/**
		 * パーティクル
		 */
		public particleCount:number;
		public particleContexts:ParticleContext[];
		public particleShuffleDistance:number;
		public useShuffleParticlePosition:boolean;
		public useShuffleParticleColor:boolean;

		/**
		 * 可視
		 */
		public particleVisibilities:number[];
		public isParticleVisibilitiesUpdated:boolean;

		/**
		 * カラー
		 */
		public particleColors:Color[];
		public particleColorSchemeIndex:number;
		public particleColorTransformDuration:number;
		public particleReferenceColors:Color[];
		public particleReferenceColorCount:number;
		public particleReferenceColorSchemes:Color[][];
		public particleReferenceColorSchemeCount:number;
		public isParticleColorsUpdated:boolean;

		/**
		 * 回転角度
		 */
		public floatingRotationTarget:number;
		public floatingRotationVelocity:number;
		public floatingRotation:number;

		public scrollRotationTarget:number;
		public scrollRotationEasing:number;
		public scrollRotation:number;
		public scrollRotationAccel:number;
		public scrollRotationVelocity:number;
	}
}