/// <reference path="../reference.ts" />

module project {

	export class Timer {

		constructor(interval:number = 1, repeatCount = 0) {
			this._interval = interval;
			this._repeatCount = repeatCount;
			this._isRunning = false;
			this._timerId = -1;
			this.reset();
		}

		/**
		 * タイマーを開始する
		 */
		public start():void {
			if (this._isRunning) return;
			this._isRunning = true;
			this._tickTime = this._getCurrentTime();
			this._startInterval(this._restTime != -1 ? this._restTime : this._interval);
		}

		/**
		 * タイマーを停止する
		 */
		public stop():void {
			if (!this._isRunning) return;
			this._isRunning = false;
			this._restTime = (this._getCurrentTime() - this._tickTime) / 1000;
			this._stopInterval();
		}

		/**
		 * タイマーを停止してリセットする
		 */
		public reset():void {
			this.stop();
			this._currentCount = 0;
			this._restTime = -1;
		}

		/**
		 * @private
		 */
		private _startInterval(interval:number):void {
			this._stopInterval();
			this._internalInterval = interval;
			this._timerId = window.setInterval(this._timerHandler, this._internalInterval * 1000);
		}

		private _stopInterval():void {
			if (this._timerId != -1) {
				clearInterval(this._timerId);
				this._timerId = -1;
			}
		}

		private _timerHandler = ():void => {
			this._tickTime = this._getCurrentTime();
			++this._currentCount;
			if (this._onTick != null) this._onTick();
			if (this._repeatCount > 0 && this._currentCount >= this._repeatCount) {
				this.stop();
				if (this._onComplete != null) this._onComplete();
			} else if (this._internalInterval != this._interval) {
				this._startInterval(this._interval);
			}
		};

		private _getCurrentTime():number {
			return new Date().valueOf();
		}





		/**
		 * 動作中の場合はtrue
		 */
		public isRunning():boolean { return this._isRunning; }
		private _isRunning:boolean;

		/**
		 * タイマーのリピート間隔(秒)
		 */
		public interval(seconds:number = null):number { return seconds != null ? this._interval = seconds : this._interval; }
		private _interval:number;

		/**
		 * 現在のリピート回数
		 */
		public currentCount():number { return this._currentCount; }
		private _currentCount:number;

		/**
		 * タイマー完了までのリピート回数
		 */
		public repeatCount(count:number = null):number { return count != null ? this._repeatCount = count : this._repeatCount; }
		private _repeatCount:number;

		/**
		 * リピート時に呼び出される関数
		 */
		public onTick(handler:Function):Function { return this._onTick = handler; }
		private _onTick:Function;

		/**
		 * 完了時に呼び出される関数
		 */
		public onComplete(handler:Function):Function { return this._onComplete = handler; }
		private _onComplete:Function;

		/**
		 * @private
		 */
		private _tickTime:number;
		private _restTime:number;
		private _timerId:number;
		private _internalInterval:number;
	}
}