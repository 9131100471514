/// <reference path="../reference.ts" />

module project {

	export class MessageScene extends BaseScene {

		constructor() {
			super(SceneId.MESSAGE, 4);
		}

		protected _implInitialize():void {
		}

		protected _implStart(useTransition:Boolean = true):void {
			this.cameraContext.distance = 1500;
			this.setParticlePositions(Vertices.SPHERE, useTransition, 1, 6000);

			/*
			var particleCount:number = this.pointCloudContext.particleCount;
			var particleContexts:ParticleContext[] = this.pointCloudContext.particleContexts;
			var particleContext:ParticleContext;
			for (var i:number = 0; i < particleCount; ++i) {
				particleContext = particleContexts[i];
				particleContext.velocityX = 300 * (Math.random() - 0.5);
				particleContext.velocityY = 300 * (Math.random() - 0.5);
				particleContext.velocityZ = 300 * (Math.random() - 0.5);
			}
			*/

			this.pointCloudContext.floatingRotationVelocity = 0.002;
		}

		protected _implStop(useTransition:Boolean = true):void {
			this.pointCloudContext.reset();
		}

		protected _implUpdate(useTransition:Boolean = true):void {
		}

		protected _implTick(useTransition:Boolean = true):void {
		}
	}
}