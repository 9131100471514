/// <reference path="../reference.ts" />

module project {

	export class MemberScene extends BaseScene {

		constructor() {
			super(SceneId.MEMBER, 4);
		}

		protected _implInitialize():void {
		}

		protected _implStart(useTransition:Boolean = true):void {
			this.cameraContext.distance = 200;
			this.setParticlePositions(Vertices.RANDOM, useTransition, 5);
		}

		protected _implStop(useTransition:Boolean = true):void {
		}

		protected _implUpdate(useTransition:Boolean = true):void {
		}

		protected _implTick(useTransition:Boolean = true):void {
		}
	}
}