/// <reference path="../reference.ts" />

module project {

	export class BottomScene extends BaseScene {

		constructor() {
			super(SceneId.BOTTOM, 4);
		}

		protected _implInitialize():void {
		}

		protected _implStart(useTransition:Boolean = true):void {
			this.cameraContext.distance = 5000;
			this.setParticlePositions(Vertices.PRODUCT, useTransition, 0.8);

			this.pointCloudContext.scrollRotationEasing = 0.1;
			this.pointCloudContext.scrollRotationAccel = 0;
			this.pointCloudContext.scrollRotationVelocity = 0;

			this.pointCloudContext.floatingRotationVelocity = 0.008;

			this.targetRotation = Math.floor(this.pointCloudContext.scrollRotationTarget / PI2) * PI2;
		}

		protected _implStop(useTransition:Boolean = true):void {
			this.pointCloudContext.reset();
		}

		protected _implUpdate(useTransition:Boolean = true):void {
			this.pointCloudContext.scrollRotationTarget = this.targetRotation;
		}

		protected _implTick(useTransition:Boolean = true):void {
		}

		private targetRotation:number;
	}
}