/// <reference path="../reference.ts" />

module project {

	export class CameraContext {

		constructor() {
			this.x = 0;
			this.y = 0;
			this.z = 0;

			this.targetX = 0;
			this.targetY = 0;
			this.targetZ = 0;

			this.roll = 0;
			this.pitch = 0;
			this.distance = 1000;
		}

		public update(tickCount:number, useTransition:Boolean = true):void {
			const easing:number = useTransition ? 0.1 : 1;

			const angleR:number = this.roll;
			const angleP:number = this.pitch;
			const sinR:number = Math.sin(angleR);
			const cosR:number = Math.cos(angleR);
			const sinP:number = Math.sin(angleP);
			const cosP:number = Math.cos(angleP);

			this.targetX = this.distance * sinR * cosP;
			this.targetY = this.distance * sinP;
			this.targetZ = this.distance * cosR * cosP;

			this.x += (this.targetX - this.x) * easing;
			this.y += (this.targetY - this.y) * easing;
			this.z += (this.targetZ - this.z) * easing;
		}

		public tick(frameCount:number, useTransition:Boolean = true):void {
		}

		public setAcceleration(pitch:number, roll:number):void {
			var angleScale:number = 0.5;

			this.pitch = pitch * angleScale;
			this.roll = roll * angleScale;
		}





		public x:number;
		public y:number;
		public z:number;

		public targetX:number;
		public targetY:number;
		public targetZ:number;

		public distance:number;
		public roll:number;
		public pitch:number;
	}
}