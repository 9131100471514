/// <reference path="../reference.ts" />

module project {

	export class Navigation {

		constructor(selector:string) {
			this._$target = jQuery(selector);

			var $desktop:JQuery = this._$target.find(".desktop");
			var $mobile:JQuery = this._$target.find(".mobile");

			var params:Object = {items: [
				{ title: "TOP", name: "top" },
				{ title: "ABSTRACT", name: "abstract" },
				{ title: "MESSAGE", name: "message" },
				{ title: "MEMBER", name: "member" },
				{ title: "ORGANIZATION", name: "organization" },
				{ title: "UPDATE", name: "update" }
			]};

			this._desktop = $desktop.miamiHeader(params);
			this._desktop.hide(0);

			this._mobile = $mobile.miamiSidebar(params);
			this._mobile.hide(0);

			window.onHeaderItemClick = this._navigationItemClickHandler;
			window.onSidebarItemClick = this._navigationItemClickHandler;

			this._desktopNavigationHeight = parseInt($desktop.css("height"));

			this._height = 0;
			this._isDesktop = null;
			this._isShown = true;
			this.hide(false);
		}

		public show(useTransition:boolean = true):void {
			if (this._isShown) return;
			this._isShown = true;
			console.log("[Navigation] show : useTransition = " + useTransition);

			if (this._isDesktop) {
				this._desktop.show(useTransition ? 1000 : 0);
			} else {
				this._mobile.show(useTransition ? 1000 : 0);
			}
		}

		public hide(useTransition:boolean = true):void {
			if (!this._isShown) return;
			this._isShown = false;
			console.log("[Navigation] hide : useTransition = " + useTransition);

			if (this._isDesktop) {
				this._desktop.hide(useTransition ? 1000 : 0);
			} else {
				this._mobile.hide(useTransition ? 1000 : 0);
			}
		}

		public resize(windowWidth:number, windowHeight:number):void {
			var isDesktop:boolean = windowWidth > 800;

			if (isDesktop != this._isDesktop) {
				this._isDesktop = isDesktop;
				this._height = this._isDesktop ? this._desktopNavigationHeight : 0;
				console.log("[Navigation] change mode : isDesktop = " + this._isDesktop);

				if (this._isShown) {
					if (this._isDesktop) {
						this._mobile.hide();
						this._desktop.show();
					} else {
						this._desktop.hide();
						this._mobile.show();
					}
				}
			}
		}

		private _navigationItemClickHandler = (name:string):void => {
			console.log("[Navigation] click item : " + name);
			if (this._onItemClick) this._onItemClick(name);
		}





		public height():number { return this._height; }
		private _height:number;

		public onItemClick(handler:Function):Function { return this._onItemClick = handler; }
		private _onItemClick:Function;

		private _$target:JQuery;
		private _isShown:boolean;
		private _isDesktop:boolean;
		private _desktopNavigationHeight:number;
		private _desktop:JQuery;
		private _mobile:JQuery;
	}
}