/// <reference path="../reference.ts" />

module project {

	export class ScrollObserver {

		constructor() {
			this._isRunning = false;

			this._$window = jQuery(window);
			this._$document = jQuery(document);

			let $trigger:JQuery = jQuery(".motion_trigger");
			this._sectionCount = $trigger.length + 1;
			this._sections = new Array<JQuery>(this._sectionCount);
			this._sectionPositions = new Array<number>(this._sectionCount);
			this._sectionIds = new Array<string>(this._sectionCount);
			let $section:JQuery;
			$trigger.each((index:number, element:HTMLElement):void => {
				$section = jQuery(element);
				this._sections[index + 1] = $section;
				this._sectionPositions[index + 1] = Number.MAX_VALUE;
				this._sectionIds[index + 1] = $section.attr("name");
			});
			this._sections[0] = null;
			this._sectionPositions[0] = 0;
			this._sectionIds[0] = "top";
			console.log("[ScrollObserver] section name : " + this._sectionIds);

			this._scrollPosition = 0;
			this._scrollRatio = 0;
			this._maxScrollPosition = 0;
			this._newSectionIndex = 0;
			this._oldSectionIndex = 0;
			this._scrollVelocity = 0;

			this._bufferTop = 50;
			this._bufferBottom = 100;
		}

		public start():void {
			if (this._isRunning) return;
			this._isRunning = true;

			this._$window.on("scroll", this._windowScrollHandler);
			this._updateScroll();
		}

		public stop():void {
			if (!this._isRunning) return;
			this._isRunning = false;

			this._$window.off("scroll", this._windowScrollHandler);
		}

		public setWindowSize(windowWidth:number, windowHeight:number):void {
			const documentHeight:number = this._$document.height();

			this._maxScrollPosition = documentHeight - windowHeight;

			let $section:JQuery;
			for (let i:number = 1; i < this._sectionCount; ++i) {
				$section = this._sections[i];
				//this._sectionPositions[i] = $section.offset().top - (windowHeight - $section.height()) * 0.5;
				this._sectionPositions[i] = $section.offset().top - windowHeight * 0.3;
			}
			console.log("[ScrollObserver] section position : " + this._sectionPositions);

			this._updateScroll();
		}

		/**
		 * @private
		 */
		private _updateScroll():void {
			const scrollPosition:number = this._$document.scrollTop();

			//スクロール速度
			this._scrollVelocity = scrollPosition - this._scrollPosition;

			//スクロール座標
			if (scrollPosition != this._scrollPosition) {
				this._scrollPosition = scrollPosition;
				this._scrollRatio = this._scrollPosition / this._maxScrollPosition;
				//console.log("[ScrollObserver] scroll : " + this._scrollPosition + " / " + this._maxScrollPosition);
				if (this._onScrollUpdate) this._onScrollUpdate();
			}

			//セクション
			let sectionIndex:number = 0;
			if (this._scrollPosition < this._bufferTop) {
				sectionIndex = 0;
			} else if (this._scrollPosition > this._maxScrollPosition - this._bufferBottom) {
				sectionIndex = this._sectionCount - 1;
			} else {
				for (let i:number = 1; i < this._sectionCount; ++i) {
					if (this._scrollPosition <= this._sectionPositions[i]) {
						sectionIndex = i - 1;
						break;
					} else if (i == this._sectionCount - 1) {
						sectionIndex = i;
					}
				}
			}
			if (sectionIndex != this._newSectionIndex) {
				this._oldSectionIndex = this._newSectionIndex;
				this._newSectionIndex = sectionIndex;
				console.log("[ScrollObserver] section : " + this._oldSectionIndex + " " + this._sectionIds[this._oldSectionIndex] + " -> " + this._newSectionIndex + " " + this._sectionIds[this._newSectionIndex]);
				if (this._onSectionUpdate) this._onSectionUpdate();
			}
		}

		private _windowScrollHandler = (event:any):void => {
			this._updateScroll();
		};





		/**
		 * 動作中の場合はtrue
		 */
		public isRunning():boolean { return this._isRunning; }
		private _isRunning:boolean;

		/**
		 * スクロール更新時に呼び出される関数
		 */
		public onScrollUpdate(handler:Function):Function { return this._onScrollUpdate = handler; }
		private _onScrollUpdate:Function;

		/**
		 * セクション更新時に呼び出される関数
		 */
		public onSectionUpdate(handler:Function):Function { return this._onSectionUpdate = handler; }
		private _onSectionUpdate:Function;

		/**
		 * 現在のスクロール量(ピクセル)
		 */
		public scrollPosition():number { return this._scrollPosition; }
		private _scrollPosition:number;

		/**
		 * 最大のスクロール雹(ピクセル)
		 */
		public maxScrollPosition():number { return this._maxScrollPosition; }
		private _maxScrollPosition:number;

		/**
		 * 現在のスクロール量[0,1]
		 */
		public scrollRatio():number { return this._scrollRatio; }
		private _scrollRatio:number;

		/**
		 * 現在のスクロール速度(ピクセル)
		 */
		public scrollVelocity():number { return this._scrollVelocity; }
		private _scrollVelocity:number;

		/**
		 * セクション
		 */
		public newSectionIndex():number { return this._newSectionIndex; }
		private _newSectionIndex:number;

		public oldSectionIndex():number { return this._oldSectionIndex; }
		private _oldSectionIndex:number;

		public sectionCount():number { return this._sectionCount; }
		private _sectionCount:number;

		public newSectionId():string { return this._newSectionIndex != -1 ? this._sectionIds[this._newSectionIndex] : null; }
		public oldSectionId():string { return this._oldSectionIndex != -1 ? this._sectionIds[this._oldSectionIndex] : null; }

		private _sections:JQuery[];
		private _sectionPositions:number[];
		private _sectionIds:string[];

		private _$window:JQuery;
		private _$document:JQuery;

		private _bufferTop:number;
		private _bufferBottom:number;
	}
}