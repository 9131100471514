/// <reference path="../../../deploy/lib/jquery/jquery.d.ts" />

/// <reference path="util/Global.ts" />
/// <reference path="util/VerticesGenerator.ts" />

/// <reference path="var/Config.ts" />
/// <reference path="var/Vertices.ts" />
/// <reference path="var/SceneId.ts" />
/// <reference path="var/ColorScheme.ts" />

/// <reference path="context/ParticleContext.ts" />
/// <reference path="context/PointCloudContext.ts" />
/// <reference path="context/CameraContext.ts" />

/// <reference path="display/Preloader.ts" />
/// <reference path="display/Navigation.ts" />
/// <reference path="display/Perspective.ts" />

/// <reference path="scene/core/BaseScene.ts" />
/// <reference path="scene/TopScene.ts" />
/// <reference path="scene/AbstractScene.ts" />
/// <reference path="scene/MessageScene.ts" />
/// <reference path="scene/MemberScene.ts" />
/// <reference path="scene/OrganizationScene.ts" />
/// <reference path="scene/UpdateScene.ts" />
/// <reference path="scene/BottomScene.ts" />

/// <reference path="job/Timer.ts" />
/// <reference path="job/Acceleration.ts" />
/// <reference path="job/ContextManager.ts" />
/// <reference path="job/ThreeJSManager.ts" />
/// <reference path="job/ScrollObserver.ts" />

/// <reference path="job/Application.ts" />

declare var THREE:any;
declare var Stats:any;
declare var Detector:any;
declare var jpp:any;

interface Window { project: any; }
window.project = window.project || {};

interface Window {
	onHeaderItemClick:Function;
	onSidebarItemClick:Function;
}

interface JQuery {
	miamiHeader(param:Object):JQuery;
	miamiSidebar(param:Object):JQuery;
}

interface Vector3 {
	x:number;
	y:number;
	z:number;
}

interface Color {
}