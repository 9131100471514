/// <reference path="../reference.ts" />

module project {

	export class Preloader {

		constructor(selector:string) {
			this._$target = jQuery(selector);

			this._$indicator = this._$target.find(".indicator");
			this._indicatorWidth = parseInt(this._$indicator.attr("width"));
			this._indicatorHeight = parseInt(this._$indicator.attr("height"));

			this._isShown = false;
			//this.hide(false);
		}

		public show(useTransition:boolean = true, onComplete:Function = null):void {
			if (this._isShown) { if (onComplete) onComplete(); return; }
			this._isShown = true;

			this._$target.css({ visibility: "visible" });
			jQueryFadeIn(this._$target, useTransition ? 1000 : 0, "easeOutQuart", onComplete);
		}

		public hide(useTransition:boolean = true, onComplete:Function = null):void {
			if (!this._isShown) { if (onComplete) onComplete(); return; }
			this._isShown = false;

			jQueryFadeOut(this._$target, useTransition ? 500 : 0, "easeOutQuart", onComplete);
		}

		public setPosition(x:number, y:number):void {
			this._$target.css({ left: x, top: y });
		}





		private _$target:JQuery;
		private _$indicator:JQuery;
		private _isShown:boolean;
		private _indicatorWidth:number;
		private _indicatorHeight:number;
	}
}