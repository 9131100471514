/// <reference path="../reference.ts" />

module project {

	export class TopScene extends BaseScene {

		constructor() {
			super(SceneId.TOP, 4);
		}

		protected _implInitialize():void {
		}

		protected _implStart(useTransition:Boolean = true):void {
			this.cameraContext.distance = 170;

			this.pointCloudContext.floatingRotation = PI * 2 / 3;
			this.pointCloudContext.floatingRotationTarget = PI * 2 / 3;

			this.setParticlePositions(Vertices.PRODUCT, useTransition, 0.8);

			//this.pointCloudContext.scrollRotationTarget = Math.floor(this.pointCloudContext.scrollRotationTarget / PI2) * PI2;
		}

		protected _implStop(useTransition:Boolean = true):void {
		}

		protected _implUpdate(useTransition:Boolean = true):void {
		}

		protected _implTick(useTransition:Boolean = true):void {
		}
	}
}