/// <reference path="../reference.ts" />

module project {

	export const JPP:any = {};

	export class Application {

		constructor() {
			this._isWindowLoaded = false;
			this._isThreeJSInitialized = false;
			this._isStarted = false;
			this._isIntro = true;
		}

		public run():void {
			jQuery(document).ready(this._documentReadyHandler);
		}

		private _documentReadyHandler = ():void => {
			console.log("[Application] document ready");

			this._isMobile = (navigator.userAgent.indexOf("iPhone") > 0 && navigator.userAgent.indexOf("iPad") == -1) || navigator.userAgent.indexOf("iPod") > 0 || navigator.userAgent.indexOf("Android") > 0;
			console.log("[Application] isMobile = " + this._isMobile);

			this._isWebGLEnabled = Config.ALT_CONTENT_MODE ? false : Detector.webgl;
			//iPhone4s以下は代替コンテンツを表示する
			if (this._isMobile && window.screen.width <= 320 && window.screen.height <= 480) {
				this._isWebGLEnabled = false;
			}
			console.log("[Application] isWebGLEnabled = " + this._isWebGLEnabled);

			//this._isAccelerometerEnabled = "ontouchstart" in window;
			this._isAccelerometerEnabled = navigator.userAgent.indexOf("iPhone") > 0 || navigator.userAgent.indexOf("iPad") > 0 || navigator.userAgent.indexOf("iPod") > 0 || navigator.userAgent.indexOf("Android") > 0;
			console.log("[Application] isAccelerometerEnabled = " + this._isAccelerometerEnabled);

			this._$window = jQuery(window);

			this._$background = jQuery(".background");
			this._$foreground = jQuery(".foreground");

			this._$title = this._$foreground.find(".title");

			this._$navigation = this._$foreground.find(".navigation");
			this._$navigation.css({ visibility: "visible" });
			(<any>window).onNavigationItemClick = this._navigationItemClickHandler;

			this._$buttons = [];
			jQuery(".button").each((index:number, element:HTMLElement):void => {
				this._$buttons.push(jQuery(element));
			});
			this._$buttonCount = this._$buttons.length;

			this._preloader = new Preloader(".preloader");
			//this._perspective = new Perspective(this._isAccelerometerEnabled);

			//if (Config.TEASER) {
			//	this._scrollObserver = new ScrollObserver();
			//	this._scrollObserver.onScrollUpdate(this._scrollUpdateHandler);
			//	this._scrollObserver.onSectionUpdate(this._scrollSectionUpdateHandler);
			//}

			this._acceleration = new Acceleration(this._isAccelerometerEnabled);
			this._acceleration.start();

			this._$window.resize(this._windowResizeHandler);
			this._windowResizeHandler();

			const mouseWheelEvent:string = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
			jQuery(window).on(mouseWheelEvent, this._mouseWheelHandler);

			this._preloader.show(true, ():void => {

				if (this._isWebGLEnabled) {
					this._threeJSManager = new ThreeJSManager(this._isMobile);
					this._threeJSManager.load(this._threeJSManagerLoadCompleteHandler);
				} else {
					this._buildAlternativeContent();
				}
			});
		};

		private _threeJSManagerLoadCompleteHandler = ():void => {
			this._updateViewport();

			if (Config.DEBUG_MODE) {
				this._stats = new Stats();
				this._stats.domElement.style.position = "fixed";
				jQuery("body").append(jQuery(this._stats.domElement));
			}

			jpp.util.Namespace('jpp.command').scope(JPP).import('*');
			jpp.util.Namespace('jpp.util').scope(JPP).import('Easing');

			this._ticker = new Timer(1);
			this._ticker.onTick(this._tickerHandler);

			this._contextManager = new ContextManager();

			let $canvas:JQuery = jQuery("<canvas>");
			this._$background.append($canvas);
			this._threeJSManager.build($canvas.get(0), this._contextManager);

			this._isThreeJSInitialized = true;

			this._start();
		};

		private _buildAlternativeContent():void {
			jQuery("section").css({ background: "none" });

			this._$backgroundImage = jQuery("<img>");
			this._$backgroundImage.attr({ src: "/asset/top/background_alt.jpg" });
			this._$background.append(this._$backgroundImage);

			this._start();
		}

		private _start():void {
			if (this._isStarted) return;
			this._isStarted = true;
			console.log("[Application] start");

			this._preloader.hide(true, ()=> {

				if (Config.BACKGROUND_CAPTURE_MODE) {
					this._$foreground.css({ visibility: "hidden" });
				}

				this._$background.css({ display: "block", opacity: 0 });
				this._$background.animate({ opacity : 1 }, 2000, "linear");

				this._$title.css({ visibility: "visible", opacity: 0 });
				this._$title.animate({ opacity : 1 }, 1500, "easeInQuart");

				if (Config.TEASER) {
					//this._scrollToBottom(false);
					//this._scrollToTop(true, 3000, "easeInOutQuart", ()=> {
					//	this._isIntro = false;
					//});
				} else {
					this._isIntro = false;
				}

				if (this._isWebGLEnabled) {
					this._tickCount = 0;
					this._ticker.start();
				}

				//if (Config.TEASER) {
				//	this._scrollObserver.start();
				//}
				this._frameCount = 0;
				this._windowResizeHandler();
				this._requestAnimationFrameHandler();
			});
		}

		private _tickerHandler = ():void => {
			++this._tickCount;

			if (this._isWebGLEnabled) {
				this._contextManager.tick(this._tickCount);
			}
		};

		private _mouseWheelHandler = (event:any):void => {
			if (this._isIntro) return;

			const delta:number = event.originalEvent.deltaY ? event.originalEvent.deltaY : event.originalEvent.wheelDelta ? -event.originalEvent.wheelDelta : event.originalEvent.detail;
			if (delta == 0) return;

			//event.preventDefault();
			//event.stopPropagation();

			if (this._isWebGLEnabled) {
				const scrollVelocity:number = delta * 2;
				this._contextManager.setScrollVelocity(scrollVelocity);
			}
		};

		/*
		private _scrollUpdateHandler = ():void => {
			if (this._isIntro) return;

			if (this._isWebGLEnabled) {
				this._contextManager.setScrollVelocity(this._scrollObserver.scrollVelocity());
			}
		}

		private _scrollSectionUpdateHandler = ():void => {
			if (this._isIntro) return;

			if (Config.TEASER) {
				if (this._isWebGLEnabled) {
					this._contextManager.setScrollSection(this._scrollObserver.newSectionId());
				}
			}
		}
		*/

		private _requestAnimationFrameHandler = ():void => {
			requestAnimationFrame(this._requestAnimationFrameHandler);
			this._update();
		};

		private _update():void {
			++this._frameCount;

			const pitch:number = this._acceleration.pitch();
			const roll:number = this._acceleration.roll();

			if (this._isWebGLEnabled) {
				this._contextManager.setAcceleration(pitch, roll);
				this._contextManager.update(this._frameCount);
				this._threeJSManager.update();
			}

			//this._perspective.update(pitch, roll);

			if (this._stats) this._stats.update();
		}

		private _updateViewport():void {
			this._windowWidth = this._$window.width();
			this._windowHeight = this._$window.height();
			console.log("[Application] window   : " + this._windowWidth + " x " + this._windowHeight);

			this._viewportWidth = this._windowWidth;
			this._viewportHeight = this._windowHeight;
			console.log("[Application] viewport : " + this._viewportWidth + " x " + this._viewportHeight);
		}

		private _windowResizeHandler = (event:JQueryEventObject = null):void => {
			this._updateViewport();

			if (this._isWebGLEnabled && this._isThreeJSInitialized) {
				this._$background.attr({ width: this._viewportWidth, height: this._viewportHeight });
				this._threeJSManager.updateViewport(this._viewportWidth, this._viewportHeight);
			}

			//if (Config.TEASER) {
			//	this._scrollObserver.setWindowSize(this._windowWidth, this._windowHeight);
			//}

			this._adjustForeground();
			this._adjustBackground();
		};

		private _adjustForeground():void {
			const stageWidth:number = this._windowWidth;
			const stageHeight:number = this._windowHeight;

			// ----------------------------------------
			// navigation
			let button:JQuery;
			let buttonX:number;
			let buttonY:number;
			let buttonMarginH:number = 40;
			let buttonMarginV:number = 40;
			let buttonWidth:number;
			let buttonHeight:number;
			let buttonWidths:number[] = new Array(this._$buttonCount);
			let buttonHeights:number[] = new Array(this._$buttonCount);
			let maxButtonWidth:number = 0;
			let maxButtonHeight:number = 0;
			for (let i:number = 0; i < this._$buttonCount; ++i) {
				button = this._$buttons[i];
				buttonWidths[i] = buttonWidth = button.width();
				buttonHeights[i] = buttonHeight = button.height();
				if (buttonWidth > maxButtonWidth) maxButtonWidth = buttonWidth;
				if (buttonHeight > maxButtonHeight) maxButtonHeight = buttonHeight;
			}

			const layouts:number[][] = [[7], [3, 4], [2, 3, 2], [1, 2, 1, 2, 1], [1, 1, 1, 1, 1, 1, 1]];
			const layoutCount:number = layouts.length;
			let colCounts:number[];
			let maxColCount:number;
			for (let i:number = 0; i < layoutCount; ++i) {
				colCounts = layouts[i];
				maxColCount = Math.max.apply(null, colCounts);
				if (stageWidth >= maxButtonWidth * maxColCount + buttonMarginH * (maxColCount + 1)) {
					break;
				}
			}

			const buttonAreaWidth:number = stageWidth / maxColCount;
			const buttonAreaHeight:number = maxButtonHeight;
			let buttonAreaColWidth:number;
			let buttonOffsetX:number;
			let buttonOffsetY:number = buttonMarginV;
			let buttonIndex:number = 0;

			const rowCount:number = colCounts.length;
			let colCount:number;
			for (let rowIndex:number = 0; rowIndex < rowCount; ++rowIndex) {
				colCount = colCounts[rowIndex];
				buttonAreaColWidth = buttonAreaWidth * colCount;
				buttonOffsetX = Math.floor((stageWidth - buttonAreaColWidth) / 2);
				for (let colIndex:number = 0; colIndex < colCount; ++colIndex) {
					button = this._$buttons[buttonIndex];
					buttonWidth = buttonWidths[buttonIndex];
					buttonHeight = buttonHeights[buttonIndex];
					buttonX = buttonOffsetX + colIndex * buttonAreaWidth + Math.floor((buttonAreaWidth - buttonWidth) / 2);
					buttonY = buttonOffsetY + Math.floor((buttonAreaHeight - buttonHeight) / 2);
					button.css({ left: buttonX, top: buttonY });
					++buttonIndex;
				}
				buttonOffsetY += buttonAreaHeight + buttonMarginV;
			}

			const navigationWidth:number = stageWidth;
			const navigationHeight:number = buttonOffsetY;

			// ----------------------------------------
			// title
			const titleMarginV:number = 40;
			const titleWidth:number = this._$title.width();
			const titleHeight:number = this._$title.height();
			const titleX:number = Math.floor((stageWidth - titleWidth) / 2);
			const titleAreaHeight:number = titleHeight + titleMarginV * 2;

			// ----------------------------------------
			// layout
			let titleY:number;
			let navigationY:number;
			if (stageHeight >= titleAreaHeight + navigationHeight) {
				titleY = Math.floor((stageHeight - navigationHeight - titleHeight) / 2);
				navigationY = stageHeight - navigationHeight;
			} else {
				titleY = titleMarginV;
				navigationY = titleAreaHeight;
			}

			this._$navigation.css({ top: navigationY, width: navigationWidth, height: navigationHeight });
			this._$title.css({ top: titleY, left: titleX });

			// ----------------------------------------
			// preloader
			this._preloader.setPosition(stageWidth / 2, titleY + titleHeight / 2);
		}

		private _adjustBackground():void {
			if (this._$backgroundImage) {
				const originalImageWidth:number = 2300;
				const originalImageHeight:number = 1162;

				const scale:number = Math.max(this._windowWidth / originalImageWidth, this._windowHeight / originalImageHeight);

				const imageWidth:number = Math.floor(scale * originalImageWidth);
				const imageHeight:number = Math.floor(scale * originalImageHeight);
				const imageLeft:number = Math.floor((this._windowWidth - imageWidth) / 2);
				const imageTop:number = Math.floor((this._windowHeight - imageHeight) / 2);

				this._$backgroundImage.attr({ width: imageWidth, height: imageHeight });
				this._$backgroundImage.css({ top: imageTop, left: imageLeft });
			}
		}

		private _navigationItemClickHandler = (name:string) => {
			console.log('[Application] navigation item : ' + name);
			$('body').animate({ opacity: 0 }, 500, 'easeOutQuart', ():void => {
				window.location.href = name;
			});
		};





		private _isWebGLEnabled:boolean;
		private _isAccelerometerEnabled:boolean;
		private _isMobile:boolean;
		private _isThreeJSInitialized:boolean;
		private _isWindowLoaded:boolean;
		private _isStarted:boolean;
		private _isIntro:boolean;

		private _frameCount:number;
		private _tickCount:number;

		private _ticker:Timer;
		private _acceleration:Acceleration;
		//private _scrollObserver:ScrollObserver;
		private _contextManager:ContextManager;
		private _threeJSManager:ThreeJSManager;

		private _windowWidth:number;
		private _windowHeight:number;

		private _viewportWidth:number;
		private _viewportHeight:number;

		//private _perspective:Perspective;
		private _preloader:Preloader;

		private _$window:JQuery;
		private _$background:JQuery;
		private _$backgroundImage:JQuery;
		private _$foreground:JQuery;
		private _$title:JQuery;
		private _$navigation:JQuery;
		private _$buttons:JQuery[];
		private _$buttonCount:number;

		private _stats:any;
	}
}