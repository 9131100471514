/// <reference path="../reference.ts" />

module project {

	export class Acceleration {

		constructor(useAccelerometer:Boolean) {
			this._isRunning = false;
			this._useAccelerometer = useAccelerometer;

			this._accelX = 0;
			this._accelY = 0;
			this._accelZ = 0;

			this._gradientX = 0;
			this._gradientY = 0;
			this._gradientZ = 0;

			this._rotationAlpha = 0;
			this._rotationBeta = 0;
			this._rotationGamma = 0;

			this._roll = 0;
			this._pitch = 0;
		}

		public start():void {
			if (this._isRunning) return;
			this._isRunning = true;

			if (this._useAccelerometer) {
				jQuery(window).on("devicemotion", this._deviceMotionHandler);
			} else {
				jQuery(window).on("mousemove", this._mouseMoveHandler);
			}
		}

		public stop():void {
			if (!this._isRunning) return;
			this._isRunning = false;

			if (this._useAccelerometer) {
				jQuery(window).on("devicemotion", this._deviceMotionHandler);
			} else {
				jQuery(window).off("mousemove", this._mouseMoveHandler);
			}
		}

		private _deviceMotionHandler = (event:any):void => {
			event = event.originalEvent;

			this._interval = event.interval;

			const acceleration:any = event.acceleration;
			this._accelX = acceleration.x;
			this._accelY = acceleration.y;
			this._accelZ = acceleration.z;

			const accelerationIncludingGravity:any = event.accelerationIncludingGravity;
			this._gradientX = accelerationIncludingGravity.x;
			this._gradientY = accelerationIncludingGravity.y;
			this._gradientZ = accelerationIncludingGravity.z;

			const rotationRate:any = event.rotationRate;
			this._rotationAlpha = rotationRate.alpha;
			this._rotationBeta = rotationRate.beta;
			this._rotationGamma = rotationRate.gamma;

			this._rowRoll = this._gradientX / 9.8;
			if (this._rowRoll < -1) this._rowRoll = -1;
			else if (this._rowRoll > 1) this._rowRoll = 1;

			this._rowPitch = this._gradientY / 9.8;
			if (this._rowPitch < -1) this._rowPitch = -1;
			else if (this._rowPitch > 1) this._rowPitch = 1;

			this._roll = this._rowRoll;
			this._pitch = this._rowPitch + 0.4;
		};

		private _mouseMoveHandler = (event:any):void => {
			const $window:JQuery = jQuery(window);

			this._rowRoll = (event.clientX / $window.width() - 0.5) * 2;
			this._rowPitch = -(event.clientY / $window.height() - 0.5) * 2;

			this._roll = -this._rowRoll;
			this._pitch = -this._rowPitch;
		};





		/**
		 * 加速度センサの計測周期
		 */
		public isRunning():Boolean { return this._isRunning; }
		private _isRunning:Boolean;


		/**
		 * 加速度センサの計測周期
		 */
		public interval():number { return this._interval; }
		private _interval:number;


		/**
		 * 加速度[m/s2]
		 */
		public accelX():number { return this._accelX; }
		private _accelX:number;

		public accelY():number { return this._accelY; }
		private _accelY:number;

		public accelZ():number { return this._accelZ; }
		private _accelZ:number;


		/**
		 * 傾き[m/s2]
		 */
		public gradientX():number { return this._gradientX; }
		private _gradientX:number;

		public gradientY():number { return this._gradientY; }
		private _gradientY:number;

		public gradientZ():number { return this._gradientZ; }
		private _gradientZ:number;


		/**
		 * 回転加速度[deg/s]
		 */
		public rotationAlpha():number { return this._rotationAlpha; }
		private _rotationAlpha:number;

		public rotationBeta():number { return this._rotationBeta; }
		private _rotationBeta:number;

		public rotationGamma():number { return this._rotationGamma; }
		private _rotationGamma:number;


		/**
		 * 正規化された傾き[-1, 1]
		 */
		public rowPitch():number { return this._rowPitch; }
		private _rowPitch:number;

		public rowRoll():number { return this._rowRoll; }
		private _rowRoll:number;

		/**
		 * コンテンツ用に補正した傾き
		 */
		public pitch():number { return this._pitch; }
		private _pitch:number;

		public roll():number { return this._roll; }
		private _roll:number;

		/**
		 * 加速度センサを使用する場合はtrue
		 */
		private _useAccelerometer:Boolean;
	}
}