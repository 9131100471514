/// <reference path="../reference.ts" />

module project {

	export class ParticleContext {

		constructor(index:number) {
			this.index = index;

			this.position = new THREE.Vector3();

			/*
			this.velocityX = 0;
			this.velocityY = 0;
			this.velocityZ = 0;
			*/

			this.colorTimer = 0;
			this.reset();
		}

		public reset():void {
			this.position.x = 0;
			this.position.y = 0;
			this.position.z = 0;
		}





		public index:number;

		public position:Vector3;

		public targetX:number;
		public targetY:number;
		public targetZ:number;

		public defaultX:number;
		public defaultY:number;
		public defaultZ:number;

		public colorTimer:number;
		public colorIndex:number;

		/*
		public velocityX:number;
		public velocityY:number;
		public velocityZ:number;
		*/
	}
}