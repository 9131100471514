/// <reference path="../reference.ts" />

module project {

	export class AbstractScene extends BaseScene {

		constructor() {
			super(SceneId.ABSTRACT, 4);
		}

		protected _implInitialize():void {
		}

		protected _implStart(useTransition:Boolean = true):void {
			this.cameraContext.distance = 1300;
			this.setParticlePositions(Vertices.BOX, useTransition, 1, 6000);

			this.pointCloudContext.floatingRotationVelocity = 0.002;
		}

		protected _implStop(useTransition:Boolean = true):void {
			this.pointCloudContext.reset();
		}

		protected _implUpdate(useTransition:Boolean = true):void {
		}

		protected _implTick(useTransition:Boolean = true):void {
		}
	}
}