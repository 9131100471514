/// <reference path="../reference.ts" />

module project {

	export class OrganizationScene extends BaseScene {

		constructor() {
			super(SceneId.ORGANIZATION, 5);
		}

		protected _implInitialize():void {
			this._fonts = [Vertices.M, Vertices.I, Vertices.A, Vertices.M, Vertices.I];
			this._fontCount = this._fonts.length;
		}

		protected _implStart(useTransition:Boolean = true):void {
			this.cameraContext.distance = 1000;

			this.pointCloudContext.floatingRotationVelocity = 0.01;

			this._fontIndex = -1;
			this._next();
		}

		protected _implStop(useTransition:Boolean = true):void {
			this.pointCloudContext.reset();
		}

		protected _implUpdate(useTransition:Boolean = true):void {
		}

		protected _implTick(useTransition:Boolean = true):void {
			this._next();

			//const defaultFloatingRotation:number = PI * 0.8;
			const defaultScrollRotation:number = Math.floor(this.pointCloudContext.scrollRotationTarget / PI2) * PI2 + PI;

			//this.pointCloudContext.floatingRotationTarget = defaultFloatingRotation + (Math.random() - 0.5) * PI2;
			this.pointCloudContext.scrollRotationTarget = defaultScrollRotation + (Math.random() - 0.5) * 2;

			this.pointCloudContext.scrollRotationEasing = 0.1;
		}

		protected _next(useTransition:Boolean = true):void {
			if (++this._fontIndex == this._fontCount) this._fontIndex = 0;
			this.setParticlePositions(this._fonts[this._fontIndex], useTransition, 1, 6000);
		}

		private _fonts:any[];
		private _fontCount:number;
		private _fontIndex:number;
	}
}