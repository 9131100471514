/// <reference path="../reference.ts" />

module project {

	export class UpdateScene extends BaseScene {

		constructor() {
			super(SceneId.UPDATE, 4);
		}

		protected _implInitialize():void {
		}

		protected _implStart(useTransition:Boolean = true):void {
			this.cameraContext.distance = 170;
			this.setParticlePositions(Vertices.PRODUCT, useTransition, 1);

			this.pointCloudContext.floatingRotationVelocity = 0.004;
		}

		protected _implStop(useTransition:Boolean = true):void {
			this.pointCloudContext.reset();
		}

		protected _implUpdate(useTransition:Boolean = true):void {
		}

		protected _implTick(useTransition:Boolean = true):void {
		}
	}
}