/// <reference path="../reference.ts" />

declare var THREE:any;

module project {

	export class ThreeJSManager {

		constructor(isMobile:boolean) {
			this._isMobile = isMobile;
		}

		public load(onLoad:Function):void {
			onLoad();
		}

		public build(canvas:HTMLElement, contextManager:ContextManager):void {
			this._canvas = canvas;
			this._contextManager = contextManager;
			this._cameraContext = this._contextManager.cameraContext;
			this._pointCloudContext = this._contextManager.pointCloudContext;

			this._defaultSize = 30;
			this._defaultFov = 75;

			//scene
			this._scene = new THREE.Scene();
			this._scene.matrixAutoUpdate = false;

			//camera
			//this._camera = new THREE.OrthographicCamera(-this._viewportWidth * 0.5, this._viewportWidth * 0.5, this._viewportHeight * 0.5, -this._viewportHeight * 0.5, 1, 10000);
			this._camera = new THREE.PerspectiveCamera(this._defaultFov, this._viewportWidth / this._viewportHeight, 1, 100000);
			this._scene.add(this._camera);

			//renderer
			this._renderer = new THREE.WebGLRenderer({ canvas: this._canvas, antialias: !this._isMobile, alpha: false });
			this._renderer.setClearColor(0xffffff);
			this._renderer.setPixelRatio(1);
			//this._renderer.setPixelRatio(window.devicePixelRatio);
			this._renderer.setSize(this._viewportWidth, this._viewportHeight);
			this._renderer.sortObject = false;

			//sphere (debug)
			/*
			if (Config.DEBUG_MODE) {
				var size:number = 1;
				var object:any = new THREE.Mesh(new THREE.SphereGeometry(size), new THREE.MeshBasicMaterial({ color: 0x000000 }));
				this._scene.add(object);
				var object:any = new THREE.Mesh(new THREE.SphereGeometry(size), new THREE.MeshBasicMaterial({ color: 0xff0000 }));
				object.position.x = 10;
				this._scene.add(object);
				var object:any = new THREE.Mesh(new THREE.SphereGeometry(size), new THREE.MeshBasicMaterial({ color: 0x00ff00 }));
				object.position.y = 10;
				this._scene.add(object);
				var object:any = new THREE.Mesh(new THREE.SphereGeometry(size), new THREE.MeshBasicMaterial({ color: 0x0000ff }));
				object.position.z = 10;
				this._scene.add(object);
			}
			*/

			//sphere
			this._sphere = new THREE.Mesh(
				new THREE.SphereGeometry(20000, 10, 10),
				new THREE.MeshBasicMaterial({
					wireframe: true,
					color: 0xcccccc
			}));
			this._scene.add(this._sphere);

			if (Config.BACKGROUND_CAPTURE_MODE && !Config.BACKGROUND_CAPTURE_MODE_PARTICLE) {
				this._sphere.visible = false;
			}

			//point cloud
			this._pointCloudAttributeVisibility = { type: "f", value: this._pointCloudContext.particleVisibilities };
			this._pointCloudAttributeColor = { type: "c", value: this._pointCloudContext.particleColors };
			this._pointCloudUniformSize = { type: "f", value: this._defaultSize };

			const attributes:Object = {
				visibility: this._pointCloudAttributeVisibility,
				color: this._pointCloudAttributeColor
			};

			const uniforms:Object = {
				size: this._pointCloudUniformSize
			};

			this._pointCloudMaterial = new THREE.ShaderMaterial({
				attributes: attributes,
				uniforms: uniforms,
				vertexShader: document.getElementById("vertexshader").textContent,
				fragmentShader: document.getElementById("fragmentshader").textContent,
				//sizeAttenuation: true,
				//size: 20,
				//vertexColors: true,
				alphaTest: 0,
				transparent: false
			});

			this._pointCloudGeometry = new THREE.Geometry();
			const particleCount:number = this._pointCloudContext.particleCount;
			const particleContexts:ParticleContext[] = this._pointCloudContext.particleContexts;
			for (let i = 0; i < particleCount; ++i) {
				this._pointCloudGeometry.vertices.push(particleContexts[i].position);
			}
			//this._pointCloudGeometry.colors = this._pointCloudContext.particleColors;

			this._pointCloud = new THREE.PointCloud(this._pointCloudGeometry, this._pointCloudMaterial);
			this._pointCloud.sortParticles = true;
			this._scene.add(this._pointCloud);

			if (Config.BACKGROUND_CAPTURE_MODE && Config.BACKGROUND_CAPTURE_MODE_PARTICLE) {
				this._pointCloud.visible = false;
			}
		}

		public update():void {
			//sphere
			let sphereRotation:Vector3 = this._sphere.rotation;
			sphereRotation.x += 0.0005;
			sphereRotation.y += 0.0005;
			sphereRotation.z += 0.0005;

			//point cloud
			let pointCloudRotation:Vector3 = this._pointCloud.rotation;
			pointCloudRotation.x = this._pointCloudContext.scrollRotation;
			pointCloudRotation.y = this._pointCloudContext.floatingRotation;

			this._pointCloudGeometry.verticesNeedUpdate = true;

			if (this._pointCloudContext.isParticleColorsUpdated) {
				this._pointCloudContext.isParticleColorsUpdated = false;
				this._pointCloudAttributeColor.needsUpdate = true;
			}

			if (this._pointCloudContext.isParticleVisibilitiesUpdated) {
				this._pointCloudContext.isParticleVisibilitiesUpdated = false;
				this._pointCloudAttributeVisibility.needsUpdate = true;
			}

			//camera
			let cameraPosition:Vector3 = this._camera.position;
			cameraPosition.x = this._cameraContext.x;
			cameraPosition.y = this._cameraContext.y;
			cameraPosition.z = this._cameraContext.z;

			this._camera.lookAt(this._scene.position);

			//render
			this._renderer.render(this._scene, this._camera);
		}

		public updateViewport(viewportWidth:number, viewportHeight:number):void {
			this._viewportWidth = viewportWidth;
			this._viewportHeight = viewportHeight;

			if (viewportWidth < 500) {
				const scale:number = 1.3;
				this._camera.fov = this._defaultFov * scale;
				this._pointCloudUniformSize.value = this._defaultSize / scale * 0.8;
			} else {
				this._camera.fov = this._defaultFov;
				this._pointCloudUniformSize.value = this._defaultSize;
			}
			this._pointCloudUniformSize.needsUpdate = true;

			//camera
			this._camera.aspect = this._viewportWidth / this._viewportHeight;
			this._camera.updateProjectionMatrix();

			//renderer
			this._renderer.setSize(this._viewportWidth, this._viewportHeight, false);
		}





		private _isMobile:boolean;

		private _contextManager:ContextManager;
		private _cameraContext:CameraContext;
		private _pointCloudContext:PointCloudContext;

		private _canvas:HTMLElement;

		private _camera:any;
		private _scene:any;
		private _renderer:any;

		private _defaultFov:number;
		private _defaultSize:number;

		private _pointCloud:any;
		private _pointCloudGeometry:any;
		private _pointCloudMaterial:any;
		private _pointCloudAttributeVisibility:any;
		private _pointCloudAttributeColor:any;
		private _pointCloudUniformSize:any;

		private _sphere:any;

		private _viewportWidth:number;
		private _viewportHeight:number;
	}
}