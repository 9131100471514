/// <reference path="../reference.ts" />

module project {

	/*
	export function trace(...message:any[]):void {
		console.log.apply(console, Array.prototype.slice.call(message));
	}
	*/

	export function jQueryFadeIn($target:JQuery, duration:number = 1, easing:string = "linear", onComplete:Function = null, displayStyle:string = "block"):void {
		$target.css({ display: displayStyle });
		$target.stop(true, true);
		$target.animate({ opacity: 1 }, duration, easing, ()=> {
			if (onComplete) onComplete();
		});
	}

	export function jQueryFadeOut($target:JQuery, duration:number = 1, easing:string = "linear", onComplete:Function = null):void {
		$target.stop(true, true);
		$target.animate({ opacity: 0 }, duration, easing, ()=> {
			$target.css({ display: "none" });
			if (onComplete) onComplete();
		});
	}

	export function map(value:number, srcMin:number, srcMax:number, dstMin:number, dstMax:number):number
	{
		value = value < srcMin ? srcMin : value > srcMax ? srcMax : value;
		return (value - srcMin) * (dstMax - dstMin) / (srcMax - srcMin) + dstMin;
	}

	export const PI = Math.PI;
	export const PI2 = PI * 2;
	export const PI_2 = PI / 2;
	export const PI_3 = PI / 3;
	export const PI_4 = PI / 4;
	export const PI_6 = PI / 6;

	export const RAD2DEG = 180 / PI;
	export const DEG2RAD = PI / 180;
}