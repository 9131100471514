/// <reference path="../reference.ts" />

module project {

	export class ColorScheme {

		public static MIAMI:number[] = [
			0xe73828, 1,
			0xd3c9b3, 12,
			0xf6efc2, 12,
			0xa3d5c0, 12,
			0x08e3d5, 12
		];

		public static MONOTONE:number[] = [
			0xff0000, 1,
			0x999999, 20,
			0xaaaaaa, 20,
			0xcccccc, 20,
			0xdddddd, 20,
			0xeeeeee, 20
		];

		/*
		public static EARTH:number[] = [
			0xb8c2f0, 1,
			0xede0ac, 1,
			0xef2e2e, 1,
			0xd5d5da, 1,
			0x6fa46f, 1,
			0xdfdf8b, 1
		];

		public static VIOLET:number[] = [
			0xfc2c89, 1,
			0xaa4ac9, 1,
			0xcce2ed, 1,
			0x56a6f0, 1
		];

		public static ORANGE:number[] = [
			0xed4018, 1,
			0xfffc20, 1,
			0xffb525, 1,
			0xbfb6e9, 1
		];

		public static GREEN:number[] = [
			0x118819, 1,
			0x9ad189, 1,
			0xffed2a, 1,
			0xb5bbec, 1,
			0xc1e3d5, 1
		];

		public static BLUE:number[] = [
			0x197fc7, 1,
			0x2a59b1, 1,
			0x9b9b9b, 1,
			0x129aa2, 1,
			0xc1e3d5, 1
		];
		*/

		//public static FLAT:number[] = [0x9999ff, 0x7fff7f, 0x66feff, 0xffa0a0, 0xff99ff, 0xfeff00];
	}
}