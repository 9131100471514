/// <reference path="../reference.ts" />

module project {

	export class ContextManager {

		constructor() {

			//パーティクル
			this.pointCloudContext = new PointCloudContext();

			//カメラ
			this.cameraContext = new CameraContext();

			//コントローラー
			const scenes:BaseScene[] = [
				new TopScene(),
				new AbstractScene(),
				new MessageScene(),
				new MemberScene(),
				new OrganizationScene(),
				new UpdateScene(),
				new BottomScene()
			];

			this._scenesById = {};
			const sceneCount:number = scenes.length;
			let scene:BaseScene;
			for (let i:number = 0; i < sceneCount; ++i) {
				scene = scenes[i];
				scene.initialize(this);
				this._scenesById[scene.id] = scene;
			}
			this._currentScene = null;

			//自動再生
			if (!Config.TEASER) {
				this._autoplaySceneIndex  = 0;
				this._autoplayScenes = scenes;
			}

			//初期状態
			this._gotoScene(SceneId.TOP, false);
		}

		public update(frameCount:number):void {
			if (this._currentScene) {
				this._currentScene.update(frameCount);
			}
			this.pointCloudContext.update(frameCount);
			this.cameraContext.update(frameCount);
		}

		public tick(tickCount:number):void {
			if (this._currentScene) {
				this._currentScene.tick(tickCount);
			}
			this.pointCloudContext.tick(tickCount);
			this.cameraContext.tick(tickCount);

			if (!Config.TEASER && this._currentScene && this._currentScene.tickCount >= this._currentScene.tickInterval) {
				this._gotoNextAutoplayScene();
			}
		}

		private _gotoNextAutoplayScene():void {
			++this._autoplaySceneIndex;
			if (this._autoplaySceneIndex >= this._autoplayScenes.length) {
				this._autoplaySceneIndex = 0;
			}
			this._gotoScene(this._autoplayScenes[this._autoplaySceneIndex].id);
		}





		/**
		 * 加速度
		 */
		public setAcceleration(pitch:number, roll:number):void {
			this.cameraContext.setAcceleration(pitch, roll);
		}





		/**
		 * スクロール
		 */
		public setScrollVelocity(scrollVelocity:number):void {
			this.pointCloudContext.setScrollVelocity(scrollVelocity);
		}

		public setScrollSection(sectionId:string):void {
			this._gotoScene(sectionId);
		}





		/**
		 * コントローラー更新
		 */
		private _gotoScene(sceneId:string, useTransition:Boolean = true):void {
			if (this._currentScene && this._currentScene.id == sceneId) return;

			console.log("[ContextManager] scene : " + sceneId);

			if (this._currentScene) {
				this._currentScene.stop();
			}

			this._currentScene = this._scenesById[sceneId] || null;
			if (this._currentScene) {
				this._currentScene.start();
			}
		}





		/**
		 * カメラ
		 */
		public cameraContext:CameraContext;

		/**
		 * パーティクル
		 */
		public pointCloudContext:PointCloudContext;

		/**
		 * コントローラー
		 */
		private _currentScene:BaseScene;
		private _scenesById:Object;

		private _autoplayScenes:BaseScene[];
		private _autoplaySceneIndex:number;
	}
}