/// <reference path="../reference.ts" />

module project {

	export class Perspective {

		constructor(useAccelerometer:boolean) {
			this._useAccelerometer = useAccelerometer;

			this._$container = jQuery(".perspective");
			this._$container.css({ perspective: 800, perspectiveOrigin: "50% 50%" });
			this._$contentStrong = this._$container.find(".perspective_content");
			this._$contentWeak = this._$container.find(".perspective_content_weak");

			this._rotatePowersByJQuery = new Object();
			this._targetRotation = 0;
			this._rotation = 0;
		}

		public update(pitch:number, roll:number):void {
			if (this._useAccelerometer) {

				this._targetRotation = -roll * 45;
				this._rotation += (this._targetRotation - this._rotation) * 0.2;
				var rotationPower = Math.abs(this._rotation / 45);
				var scale:number = 1 + 0.2 * rotationPower * rotationPower;
				this._$contentStrong.css({ transform: "rotateY(" + this._rotation + "deg) scale(" + scale + ")" });
				this._$contentWeak.css({ transform: "rotateY(" + (this._rotation * 0.5) + "deg) scale(" + scale + ")" });

			} else {
				this._targetRotation = roll * 30;
				this._rotation += (this._targetRotation - this._rotation) * 0.1;
				this._$contentStrong.css({ transform: "rotateY(" + this._rotation + "deg)" });
				this._$contentWeak.css({ transform: "rotateY(" + (this._rotation * 0.5) + "deg)" });
			}
		}





		private _$container:JQuery;
		private _$contentStrong:JQuery;
		private _$contentWeak:JQuery;
		private _rotatePowersByJQuery:Object;
		private _useAccelerometer:boolean;
		private _rotation:number;
		private _targetRotation:number;
	}
}