/// <reference path="../reference.ts" />

module project {

	export class Config {

		public static TEASER:Boolean = false;

		public static DEBUG_MODE:Boolean = false;
		public static ALT_CONTENT_MODE:Boolean = false;
		public static BACKGROUND_CAPTURE_MODE:Boolean = false;
		public static BACKGROUND_CAPTURE_MODE_PARTICLE:Boolean = false;

		public static PARTICLE_COUNT:number = 19611; //6000
		public static PARTICLE_TEXTURE_IDS:string[] = ["circle", "rectangle", "triangle"];
	}
}