/// <reference path="../../reference.ts" />

module project {

	export class BaseScene {

		constructor(id:string, tickInterval:number) {
			this.id = id;
			this.tickInterval = tickInterval;
		}

		public initialize(contextManager:ContextManager):void {
			this.contextManager = contextManager;
			this.cameraContext = this.contextManager.cameraContext;
			this.pointCloudContext = this.contextManager.pointCloudContext;
			this._implInitialize();
		}

		public start(useTransition:Boolean = true):void {
			this.frameCount = 0;
			this.tickCount = 0;
			this._implStart(useTransition);
		}

		public stop(useTransition:Boolean = true):void {
			this._implStop(useTransition);
		}

		public update(globalFrameCount:number, useTransition:Boolean = true):void {
			this.globalFrameCount = globalFrameCount;
			++this.frameCount;
			this._implUpdate(useTransition);
		}

		public tick(globalTickCount:number, useTransition:Boolean = true):void {
			this.globalTickCount = globalTickCount;
			++this.tickCount;
			this._implTick(useTransition);
		}

		public setParticlePositions(vertices:number[], useTransition:Boolean = true, scale:number = 1, visibleParticleCount:number = Number.MAX_VALUE):void {
			const particleCount:number = this.pointCloudContext.particleCount;
			const particleContexts:ParticleContext[] = this.pointCloudContext.particleContexts;
			let particleVisibilities:number[] = this.pointCloudContext.particleVisibilities;
			let particleContext:ParticleContext;
			let particlePosition:Vector3;
			let px:number;
			let py:number;
			let pz:number;
			for (let i:number = 0; i < particleCount; ++i) {
				particleContext = particleContexts[i];

				px = scale * vertices[i * 3];
				py = scale * vertices[i * 3 + 1];
				pz = scale * vertices[i * 3 + 2];

				particleContext.defaultX = px;
				particleContext.defaultY = py;
				particleContext.defaultZ = pz;

				particleContext.targetX = px;
				particleContext.targetY = py;
				particleContext.targetZ = pz;

				particleVisibilities[i] = i < visibleParticleCount ? 1 : 0;

				if (!useTransition) {
					particlePosition = particleContext.position;
					particlePosition.x = px;
					particlePosition.y = py;
					particlePosition.z = pz;
				}
			}

			this.pointCloudContext.isParticleVisibilitiesUpdated = true;
		}





		protected _implInitialize():void {
		}

		protected _implStart(useTransition:Boolean = true):void {
		}

		protected _implStop(useTransition:Boolean = true):void {
		}

		protected _implUpdate(useTransition:Boolean = true):void {
		}

		protected _implTick(useTransition:Boolean = true):void {
		}





		public id:string;

		public contextManager:ContextManager;
		public cameraContext:CameraContext;
		public pointCloudContext:PointCloudContext;

		public frameCount:number;
		public tickCount:number;
		public tickInterval:number;

		public globalFrameCount:number;
		public globalTickCount:number;
	}
}