/// <reference path="../reference.ts" />

module project {

	export class SceneId {

		public static TOP:string = "top";
		public static ABSTRACT:string = "abstract";
		public static MESSAGE:string = "message";
		public static MEMBER:string = "member";
		public static ORGANIZATION:string = "organization";
		public static UPDATE:string = "update";
		public static BOTTOM:string = "bottom";
	}
}